<template>
  <v-card outlined class="pa-4">
    <v-card-title class="font-weight-bold">MROI Curve</v-card-title>
    <MROILineChart
      :chartData="chartData"
      :options="options"
      class="row pt-4 pb-2 pr-4 pl-2"
    ></MROILineChart>
  </v-card>
</template>

<script>
export default {
  name: "MROICurve",
  components: {
    MROILineChart: () => import("@/components/chart/MROILineChart"),
  },
  data: () => ({}),
  computed: {
    chartData() {
      return this.$store.getters.promoOverviewMROIChartData;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    borderColor() {
      return this.$vuetify.theme.themes[this.theme].borderColor;
    },
    options() {
      const budget_label = this.$t("budget");
      const gridlines_color = this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
      const fontColor = this.$vuetify.theme.themes[this.theme].chartFont;
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              id: "time-axis",
              type: "linear",
              display: true,
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              scaleLabel: {
                display: true,
                fontColor: fontColor,
                labelString: this.$t("Spend"),
              },
              ticks: {
                beginAtZero: true,
                fontColor: fontColor,
                callback: function(value) {
                  if (parseInt(value) >= 1000) {
                    return value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return value;
                  }
                },
              },
            },
          ],
          yAxes: [
            {
              id: "y-axis",
              display: true,
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              scaleLabel: {
                display: true,
                fontColor: fontColor,
                labelString: this.$t("metricNames.MROI"),
              },
              ticks: {
                fontColor: fontColor,
              },
            },
          ],
        },
        tooltips: {
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            label: function(tooltipItem) {
              const formatNum = Math.round(tooltipItem.yLabel * 100) / 100;
              return `MROI: ${formatNum}`;
            },
            title: function(tooltipItem) {
              const formatNum = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(tooltipItem[0].xLabel);
              return `${budget_label}: ${formatNum}`;
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped></style>
